import { calendarClient } from './axiosClients';

// Calendar Service - Bookings API
export function getPropertyAvailableDates(
  propertyId,
  available_only,
  start_at,
  end_at
) {
  return calendarClient.get(`/api/property/${propertyId}/slots/dates/`, {
    params: { start_at, end_at, available_only }
  });
}

export function getPropertyAvailableSlots(
  propertyId,
  available_only,
  start_at,
  end_at
) {
  return calendarClient.get(`/api/property/${propertyId}/slots/`, {
    params: { limit: 287, start_at, end_at, available_only }
  });
}

export function createPropertyVisitBooking(propertyId, slotId, candidate) {
  return calendarClient.post(
    `/api/property/${propertyId}/slots/${slotId}/bookings/`,
    candidate
  );
}

export function createPropertyTimeSlot(propertyId, slot) {
  return calendarClient.post(`/api/property/${propertyId}/slots/`, slot);
}

export function deletePropertyTimeSlot(propertyId, slotId) {
  return calendarClient.delete(`/api/property/${propertyId}/slots/${slotId}/`);
}

export function createPropertyVisitWaitlistEntry(propertyId, data) {
  return calendarClient.post(
    `/api/property/${propertyId}/waitlist-entries/`,
    data
  );
}

export function createVisitRequest(propertyId, data) {
  return calendarClient.post(
    `/api/property/${propertyId}/visit-request/`,
    data
  );
}
